* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Titillium Web", sans-serif;
  height: 100%;
  padding: 0;
  margin: 0;
  font-weight: 400;
}
